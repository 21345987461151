import { ref, computed } from 'vue'
import type { ComputedRef, WritableComputedRef } from 'vue'
import type { PageContentSidebarLink } from '@/definitions/_layout/pageContent/sidebar/types'
import { actions } from '@/router/sidebarRoutes/ticketsRoutes'

type UseTicketsGettingStarted = {
  hideGettingStartedTab: () => void;
  getGettingStartedState: ComputedRef<boolean>;
  getSimulateTicketState: ComputedRef<boolean>;
  startSimulateTicket: () => void;
  tooltipState: WritableComputedRef<boolean>;
  getOpenLink: ComputedRef<PageContentSidebarLink>;
}

const gettingStartedState = ref(true)
const simulateTicketState = ref(false)
const tooltip = ref(false)

export const useTicketsGettingStarted = (): UseTicketsGettingStarted => {
  const hideGettingStartedTab = () => {
    gettingStartedState.value = false
  }

  const getGettingStartedState = computed(() => gettingStartedState.value)

  const startSimulateTicket = () => {
    simulateTicketState.value = true
    tooltipState.value = true
  }

  const getSimulateTicketState = computed(() => simulateTicketState.value)
  const tooltipState = computed({
    get: () => tooltip.value,
    set: (value: boolean) => {
      tooltip.value = value
    },
  })

  const getOpenLink = computed(() => ({
    itemType: 'link',
    title: 'Open',
    number: getSimulateTicketState.value ? '1' : '0',
    lightItem: !getSimulateTicketState.value,
    actions: actions,
    tooltip: getSimulateTicketState.value ? 'You have just received a demo ticket' : '',
    tooltipVisible: tooltipState.value,
    emoji: 'help',
    route: {
      name: 'base.tickets.main.index',
      params: {
        title: 'Open',
        ticketsItemsStartAt: 0,
        ticketsItemsEndAt: 0,
        simulateTicket: getSimulateTicketState.value.toString(),
      },
    },
  }))

  return {
    getOpenLink,
    tooltipState,
    getSimulateTicketState,
    getGettingStartedState,
    startSimulateTicket,
    hideGettingStartedTab,
  }
}
