export const keyCodes: Record<string, string> = {
  keyEnter: 'Enter',
  keyTab: 'Tab',
  keyEsc: 'Escape',
  keySpace: 'Space',
  keyUp: 'ArrowUp',
  keyDown: 'ArrowDown',
  keyLeft: 'ArrowLeft',
  keyRight: 'ArrowRight',
  keyBackspace: 'Backspace',
  keyDelete: 'Delete',
  keyEnd: 'End',
  keyHome: 'Home',
  keyInsert: 'Insert',
  keyPageUp: 'PageUp',
  keyPageDown: 'PageDown',
} as const
