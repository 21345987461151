import type { ComputedRef, WritableComputedRef } from 'vue'
import { computed, ref } from 'vue'
import { getTableData } from '@/services/tableService'
import { useModals } from '@/compositions/modals'
import type {
  ChatActiveCallType,
  ChatCallTypes,
  ChatLogItemType,
  ChatSenderType,
  ChatType
} from '@/definitions/shared/chat/types'
import { checkMatch } from '@/services/string'
import { getUid, randomIntFromInterval } from '@/services/utils'
import moment from 'moment'
import { useRoute } from 'vue-router'
import type { MessengerFilterItem } from '@/definitions/chats/sidebar/types'

const { openModal } = useModals()

type ClearChatType = () => void
type UpdateChatType = (chat: ChatType) => void
type UpdateChatsType = (chats: ChatType[]) => void
type StartChatType = (chatSender: ChatSenderType) => void

type UseSidebarType = {
  search: WritableComputedRef<string>;
  searchNewChat: WritableComputedRef<string>;
  searchVisible: ComputedRef<boolean>;
  showSelection: WritableComputedRef<boolean>;
  showNewChat: () => void;
  hideNewChat: () => void;
  newChatVisible: ComputedRef<boolean>;
  selected: ComputedRef<ChatType[]>;
  isAllSelected: ComputedRef<boolean | null>;
  getActiveFilter: ComputedRef<MessengerFilterItem>
  setActiveFilter: (item: MessengerFilterItem) => void;
}

type UseContentType = {
  search: WritableComputedRef<string>;
  showSearch: WritableComputedRef<boolean>;
  showSelection: WritableComputedRef<boolean>;
  showDate: WritableComputedRef<boolean>;
  from: WritableComputedRef<Date | null>;
  to: WritableComputedRef<Date | null>;
  selected: ComputedRef<ChatLogItemType[]>;
  isAllSelected: ComputedRef<boolean | null>;
  toggleAllSelected: (resetAll: boolean) => void;
}

type UseChatsType = {
  updateChat: UpdateChatType;
  updateChats: UpdateChatsType;
  setActiveChat: UpdateChatType;
  clearActiveChat: ClearChatType;
  startChat: StartChatType;
  getChatData: ComputedRef<ChatType[]>;
  showMobileChat: WritableComputedRef<boolean>;
  showChatDetails: WritableComputedRef<boolean>;
  getActiveChat: ComputedRef<ChatType | null>;
  getLastMessageStatus: ComputedRef<ChatCallTypes | null>;
}

type DialpadStateType = 'main' | 'contacts' | 'history' | 'hideDialpad' | 'settings'
type DialpadFilterTypes = 'my' | 'missed' | 'all'

type UseDialpadType = {
  showDialpad: WritableComputedRef<boolean>;
  fieldValue: WritableComputedRef<string>;
  getState: ComputedRef<DialpadStateType>;
  setState: (state: DialpadStateType) => void;
  getCurrentFilter: ComputedRef<DialpadFilterTypes>;
  setCurrentFilter: (filter: DialpadFilterTypes) => void;
}

type CallViewsType = 'main' | 'settings'

type UseCallsType = {
  showCall: WritableComputedRef<boolean>;
  currentView: WritableComputedRef<CallViewsType>;
  setActiveCall: (call: ChatActiveCallType) => void;
  getActiveCall: ComputedRef<ChatActiveCallType>;
  toggleMute: () => void;
}

// Sidebar vars
const sidebarSearch = ref('')
const sidebarSearchNewChat = ref('')
const showSidebarSelection = ref(false)
const showSidebarSearch = ref(false)
const showSidebarNewChat = ref(false)
const sidebarActiveFilter = ref()

// Content vats
const contentSearch = ref<string>('')
const contentDateFrom = ref<Date | null>(null)
const contentDateTo = ref<Date | null>(null)
const showContentSearch = ref(false)
const showContentDate = ref(false)
const showContentSelection = ref(false)

// General vars
const chatsMobile: ChatType[] = getTableData('chats').map((e: ChatType) => {
  return {
    ...e,
    selected: false,
    type: 'mobile',
  }
})

const chatsEmail: ChatType[] = getTableData('chatsEmail').map((e: ChatType) => {
  return {
    ...e,
    selected: false,
    type: 'email',
    channel: randomIntFromInterval(1, 2) === 1 ? 'email' : 'outlook',
  }
})

const chatsEmailLive: ChatType[] = getTableData('chatsEmailLive').map((e: ChatType) => {
  return {
    ...e,
    selected: false,
    type: 'live',
    channel: 'liveChatGlobal',
  }
})

const chats = ref<ChatType[]>(chatsMobile.concat(chatsEmail, chatsEmailLive))
// const chats = ref<ChatType[]>(chatsEmailLive)

const filteredChats = computed<ChatType[]>(() => {
  const route = useRoute()
  let sortedChats: ChatType[] = chats.value.slice().sort((a: ChatType, b: ChatType) => {
    const chatLastMessageA: ChatLogItemType | undefined = a.chatLog.slice().pop()
    const chatLastMessageB: ChatLogItemType | undefined = b.chatLog.slice().pop()

    const dateCompare = () => {
      if (!chatLastMessageA) {
        return -1
      }

      return chatLastMessageB
        ? moment(chatLastMessageB.date).isBefore(moment(chatLastMessageA.date)) ? -1 : 1
        : -1
    }

    if (a.pinned || b.pinned) {
      return a.pinned && b.pinned
        ? dateCompare()
        : a.pinned ? -1 : 1
    }

    return dateCompare()
  })

  // filter live chats
  sortedChats = sortedChats.filter((chat: ChatType) => {
    const lastMessageType = chat.chatLog.slice().pop()?.callType
    if (!lastMessageType) return false

    switch (route.name) {
      case 'base.chats.waiting-now':
        if (chat.type !== 'live') return false
        return ['waiting', 'missed'].includes(lastMessageType)
      case 'base.chats.open':
        return ['ended', 'open'].includes(lastMessageType)
      case 'base.chats.solved':
        return ['solved'].includes(lastMessageType)
      default:
        return true
    }
  }).sort((a: ChatType, b: ChatType) => {
    if (route.name === 'base.chats.waiting-now') {
      const lastMessageA = a.chatLog[a.chatLog.length - 1]
      const lastMessageB = b.chatLog[b.chatLog.length - 1]
      if (lastMessageA.callType === 'waiting' && lastMessageB.callType === 'missed') return -1
      if (lastMessageA.callType === 'missed' && lastMessageB.callType === 'waiting') return 1
    }

    return 0
  })

  if (!sidebarSearch.value) {
    return sortedChats
  }

  return sortedChats.filter((chat: ChatType) => !!chat.chatLog.find((e: ChatLogItemType) => {
    if (chat.chatSender.person && checkMatch(chat.chatSender.person.fullName, sidebarSearch.value)) {
      return true
    }

    return checkMatch(chat.chatSender.phone, sidebarSearch.value)
  }))
})

const activeChat = ref<ChatType | null>(null)
const mobileChatVisible = ref(false)
const chatDetailsVisible = ref(true)

// Dialpad vars
const dialpadVisible = ref(false)
const dialpadValue = ref('')
const dialpadState = ref<DialpadStateType>('main')
const dialpadFilter = ref<DialpadFilterTypes>('all')

// Call vars
const callVisible = ref(false)
const activeCall = ref<ChatActiveCallType>(null)
const callView = ref<CallViewsType>('main')

export const useCalls = (): UseCallsType => {
  const showCall = computed({
    get: () => callVisible.value,
    set: (value: boolean) => {
      callVisible.value = value
    },
  })
  const currentView = computed({
    get: () => callView.value,
    set: (value: CallViewsType) => {
      callView.value = value
    },
  })
  const setActiveCall = (call: ChatActiveCallType) => {
    activeCall.value = call
  }
  const getActiveCall = computed((): ChatActiveCallType => activeCall.value)
  const toggleMute = () => {
    setActiveCall({
      ...getActiveCall.value!,
      muted: !getActiveCall.value!.muted,
    })
  }

  return {
    toggleMute,
    currentView,
    getActiveCall,
    setActiveCall,
    showCall,
  }
}

export const useDialpad = (): UseDialpadType => {
  const showDialpad = computed({
    get: () => dialpadVisible.value,
    set: (value: boolean) => {
      dialpadVisible.value = value
    },
  })
  const fieldValue = computed({
    get: () => dialpadValue.value,
    set: (value: string) => {
      dialpadValue.value = value
    },
  })
  const getState = computed(() => dialpadState.value)
  const setState = (state: DialpadStateType, resetField?: boolean) => {
    if (resetField) {
      fieldValue.value = ''
    }
    dialpadState.value = state
  }
  const getCurrentFilter = computed(() => dialpadFilter.value)
  const setCurrentFilter = (filter: DialpadFilterTypes) => {
    dialpadFilter.value = filter
  }

  return {
    setCurrentFilter,
    getCurrentFilter,
    fieldValue,
    showDialpad,
    getState,
    setState,
  }
}

export const useChats = (): UseChatsType => {
  const updateChat: UpdateChatType = (chat) => {
    chats.value = chats.value.map((e: ChatType) => e.id === chat.id ? chat : e)

    if (activeChat.value && chat.id === activeChat.value.id) {
      activeChat.value = chat
    }
  }
  const updateChats: UpdateChatsType = (updatedChats) => {
    chats.value = updatedChats

    updatedChats.forEach((e: ChatType) => {
      if (activeChat.value && e.id === activeChat.value.id) {
        activeChat.value = e
      }
    })
  }

  const startChat: StartChatType = (chatSender: ChatSenderType) => {
    const uid = getUid()

    chats.value.push({
      id: uid,
      chatSender: chatSender,
      chatLog: [],
      pinned: false,
      notifications: 0,
      type: 'mobile',
      selected: false,
    })

    setActiveChat(chats.value.find((e: ChatType) => e.id === uid)!)
  }

  const setActiveChat: UpdateChatType = (chat) => {
    activeChat.value = chat
  }

  const clearActiveChat: ClearChatType = () => {
    activeChat.value = null
  }

  const getChatData = computed((): ChatType[] => filteredChats.value)
  const getActiveChat = computed((): ChatType | null => activeChat.value)
  const getLastMessageStatus = computed(() => {
    const lastMessage = getActiveChat.value?.chatLog.slice().pop() || null

    return lastMessage?.callType || null
  })

  const showMobileChat = computed({
    get: () => mobileChatVisible.value,
    set: (value: boolean) => {
      mobileChatVisible.value = value
    },
  })
  const showChatDetails = computed({
    get: () => chatDetailsVisible.value,
    set: (value: boolean) => {
      chatDetailsVisible.value = value
    },
  })

  return {
    clearActiveChat,
    updateChat,
    showMobileChat,
    showChatDetails,
    startChat,
    getChatData,
    getActiveChat,
    getLastMessageStatus,
    updateChats,
    setActiveChat,
  }
}

export const useContent = (): UseContentType => {
  const search = computed({
    get: () => contentSearch.value,
    set: (value: string) => {
      contentSearch.value = value
    },
  })

  const toggleAllSelected = (resetAll: boolean) => {
    if (!activeChat.value) {
      return
    }

    const chatLog = activeChat.value.chatLog.map((e: ChatLogItemType) => {
      return {
        ...e,
        selected: resetAll ? false : !isAllSelected.value,
      }
    })

    const { updateChat } = useChats()

    updateChat({ ...activeChat.value, chatLog: chatLog })
  }

  const from = computed({
    get: () => contentDateFrom.value,
    set: (value: Date | null) => {
      contentDateFrom.value = value
    },
  })

  const to = computed({
    get: () => contentDateTo.value,
    set: (value: Date | null) => {
      contentDateTo.value = value
    },
  })

  const selected = computed<ChatLogItemType[]>(() => activeChat.value ? activeChat.value.chatLog.filter((e: ChatLogItemType) => e.selected) : [])
  const isAllSelected = computed((): boolean | null => {
    if (!activeChat.value) { return false }
    if (!selected.value.length) { return false }
    if (selected.value.length === activeChat.value.chatLog.length) { return true }

    return null
  })

  const showSearch = computed({
    get: () => showContentSearch.value,
    set: (value: boolean) => {
      showContentSearch.value = value
    },
  })
  const showDate = computed({
    get: () => showContentDate.value,
    set: (value: boolean) => {
      showContentDate.value = value
    },
  })
  const showSelection = computed({
    get: () => showContentSelection.value,
    set: (value: boolean) => {
      showContentSelection.value = value
    },
  })

  return {
    isAllSelected,
    showSelection,
    selected,
    search,
    from,
    toggleAllSelected,
    to,
    showSearch,
    showDate,
  }
}

export const useSidebar = (): UseSidebarType => {
  const getActiveFilter = computed(() => sidebarActiveFilter.value)
  const setActiveFilter = (item: MessengerFilterItem) => {
    sidebarActiveFilter.value = item
  }

  const showSelection = computed({
    get: () => showSidebarSelection.value,
    set: (value: boolean) => {
      showSidebarSelection.value = value
    },
  })

  const search = computed({
    get: () => sidebarSearch.value,
    set: (value: string) => {
      sidebarSearch.value = value
    },
  })

  const selected = computed((): ChatType[] => filteredChats.value.filter((e: ChatType) => e.selected))
  const isAllSelected = computed((): boolean | null => {
    if (!selected.value.length) {
      return false
    }
    if (filteredChats.value.length === selected.value.length) {
      return true
    }
    return null
  })

  const searchVisible = computed(() => showSidebarSearch.value)

  // New chat variables
  const searchNewChat = computed({
    get: () => sidebarSearchNewChat.value,
    set: (value: string) => {
      sidebarSearchNewChat.value = value
    },
  })
  const showNewChat = () => {
    showSidebarNewChat.value = true
    searchNewChat.value = ''
  }
  const hideNewChat = () => {
    showSidebarNewChat.value = false
    searchNewChat.value = ''
  }
  const newChatVisible = computed(() => showSidebarNewChat.value)

  return {
    isAllSelected,
    showSelection,
    selected,
    search,
    searchVisible,
    showNewChat,
    hideNewChat,
    newChatVisible,
    searchNewChat,
    getActiveFilter,
    setActiveFilter,
  }
}

type UseChatsModals = {
  deleteChat: () => void;
  assignChat: () => void;
  solveChat: () => void;
  assigneeModal: () => void;
  deleteFilteredView: () => void;
  rejectChat: () => void;
  solveReadChat: () => void;
  solveMissedChat: () => void;
}

export const useChatsModals = (): UseChatsModals => {
  const selectedChatsLength = computed((): number => filteredChats.value.filter((e: ChatType) => e.selected).length)
  const isSingle = computed((): boolean => selectedChatsLength.value <= 1)
  const postfix = computed((): string => isSingle.value ? '' : 's')

  const assigneeModal = () => {
    openModal('singleAssignee', {
      label: 'Automatically assign to',
      btnText: 'Save',
      modalTitle: 'Assignee',
    })
  }
  const rejectChat = () => {
    openModal('chatsRejectLiveChat')
  }
  const deleteChat = () => {
    openModal('confirmation', {
      modalTitle: `Delete chat${postfix.value}`,
      text: isSingle.value
        ? [
            'Are you sure you would like to delete this chat? All messages contained in this chat will be',
            {
              text: ' deleted permanently from all pages ',
              style: 'semi-bold',
            },
            'and cannot be restored in future.',
          ]
        : [
            'Are you sure you would like to delete selected chats? All messages contained in these chats will be',
            {
              text: ' deleted permanently from all pages ',
              style: 'semi-bold',
            },
            'and cannot be restored in future.',
          ],
      btnText: isSingle.value ? 'Delete' : `Delete ${selectedChatsLength.value} chat${postfix.value}`,
      btnColor: 'error',
    })
  }
  const assignChat = () => {
    openModal('singleAssignee', {
      modalTitle: `Assign chat${postfix.value}`,
      text: `Please select an agent to whom you would like to assign the selected chat${postfix.value}.`,
      label: 'Assignee',
      btnText: isSingle.value ? 'Assign' : `Assign ${selectedChatsLength.value} chat${postfix.value}`,
    })
  }

  const deleteFilteredView = () => {
    openModal('confirmation', {
      modalTitle: 'Delete filtered view',
      text: [
        'Are you sure you want to delete the',
        {
          text: ' Assigned to me ',
          style: 'semi-bold',
        },
        'filtered view? This action cannot be undone.',
      ],
      btnText: 'Delete',
      btnColor: 'error',
    })
  }

  const solveChatsModalData = [
    'Are you sure you would like to mark the selected chats as solved? \n\n',
    {
      text: 'Please note:',
      style: 'semi-bold',
    },
    ' any ongoing live chat conversations will end after this action.',
  ]
  const solveChat = () => {
    openModal('confirmation', {
      modalTitle: `Mark chat${postfix.value} as solved`,
      text: isSingle.value
        ? [
            'You have an ongoing conversation with ',
            {
              text: 'Cody Fisher.',
              style: 'semi-bold',
            },
            '\nAre you sure you would like to mark this chat as solved? This\n' +
            'action will end the chat for both parties.',
          ]
        : solveChatsModalData,
      btnText: isSingle.value ? 'Solve' : `Solve ${selectedChatsLength.value} chats`,
      btnColor: 'primary',
    })
  }

  const solveReadChat = () => {
    openModal('confirmation', {
      modalTitle: 'Mark all read chats as solved',
      text: solveChatsModalData,
      btnText: 'Solve all read chats',
      btnColor: 'primary',
    })
  }

  const solveMissedChat = () => {
    openModal('confirmation', {
      modalTitle: 'Mark all missed chats as solved',
      text: [
        'Are you sure you would like to mark all missed chats as solved? The status of these chats will be set to ',
        {
          text: 'Solved.',
          style: 'semi-bold',
        },
      ],
      btnText: 'Solve all missed chats',
      btnColor: 'primary',
    })
  }

  return {
    deleteFilteredView,
    assigneeModal,
    solveChat,
    deleteChat,
    assignChat,
    rejectChat,
    solveReadChat,
    solveMissedChat,
  }
}
