import { createApp } from 'vue'
import Quasar from 'quasar/src/vue-plugin.js';
import Notifications from '@kyvg/vue3-notification'

// eslint-disable-next-line
// @ts-ignore
import vClickOutside from 'click-outside-vue3'
import quasarOptions from '@/quasarOptions'
import App from '@/components/views/App.vue'
import router from '@/router'
import './validationRules'
import Field from '@/components/shared/field/Field.vue'
import TmIcon from '@/components/shared/TmIcon.vue'

// FYI: it is important to import quasar here instead of main styles file
// In another way it won't work. There is issue with quasar styles import
import '@/styles/quasar.scss'
import '@/styles/app'

const vueApp = createApp(App)

vueApp.use(Quasar, quasarOptions)
vueApp.use(vClickOutside)
vueApp.use(router)
vueApp.use(Notifications)

vueApp.component('TmField', Field)
vueApp.component('TmIcon', TmIcon)

vueApp.mount('#app')
