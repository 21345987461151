import type { FlagCountry } from '@/definitions/shared/types'

const phoneCountryVariant: FlagCountry[] = [
  {
    name: 'United States',
    id: 'us',
  }, {
    name: 'Denmark',
    id: 'dk',
  }, {
    name: 'France',
    id: 'fr',
  }, {
    name: 'Estonia',
    id: 'ee',
  }, {
    name: 'Germany',
    id: 'de',
  },
]

export const phoneCountryWithCodeVariant: FlagCountry[] = [
  {
    name: 'United States',
    id: 'us',
    code: '(+1)',
  },
  {
    name: 'United Kingdom',
    id: 'uk',
    code: '(+44)',
  },
  {
    name: 'Denmark',
    id: 'dk',
    code: '(+45)',
  },
  {
    name: 'France',
    id: 'fr',
    code: '(+33)',
  },
  {
    name: 'Estonia',
    id: 'ee',
    code: '(+372)',
  },
  {
    name: 'Germany',
    id: 'de',
    code: '(+49)',
  },
]

export default phoneCountryVariant
