export const utcZones: string[] = [
  '(UTC -12:00): International Date Line West',
  '(UTC -11:00): Coordinated Universal Time-11',
  '(UTC -11:00): Samoa',
  '(UTC -10:00): Hawaii',
  '(UTC -09:30): Marquesas Islands',
  '(UTC -09:00): Alaska',
  '(UTC -08:00): Baja California',
  '(UTC -08:00): Pacific Time (US & Canada)',
  '(UTC -07:00): Chihuahua,Mazatlan',
  '(UTC -07:00): Arizona',
  '(UTC -07:00): Mountain Time (US & Canada)',
  '(UTC -06:00): Central Time (US & Canada)',
  '(UTC -06:00): Central America',
  '(UTC -06:00): Guadalajara, Mexico, City, Monterrey',
  '(UTC -06:00): Saskatchewan',
  '(UTC -05:00): Bogota, Lima, Quito',
  '(UTC -05:00): Eastern Time (US & Canada)',
  '(UTC -05:00): Indiana (East)',
  '(UTC -04:30): Caracas',
  '(UTC -04:00): Atlantic Time (Canada)',
  '(UTC -04:00): Asuncion',
  '(UTC -04:00): Cuiaba',
  '(UTC -04:00): Santiago',
  '(UTC -04:00): Georgetown, La Paz, Manaus, San Juan',
  '(UTC -03:30): Newfoundland',
  '(UTC -03:00): Buenos Aires',
  '(UTC -03:00): Brasilia',
  '(UTC -03:00): Cayenne, Fortaleza',
  '(UTC -03:00): Montevideo',
  '(UTC -03:00): Greenland',
  '(UTC -02:00): Coordinated Universal Time-02',
  '(UTC -02:00): Mid-Atlantic',
  '(UTC -01:00): Azores',
  '(UTC -01:00): Cape Verde Is.',
  '(UTC +00:00): Casablanca',
  '(UTC +00:00): Monrovia, Reykjavik',
  '(UTC +00:00): Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London',
  '(UTC +01:00): Sarajevo, Skopje, Warsaw, Zagreb',
  '(UTC +01:00): West Central Africa',
  '(UTC +01:00): Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  '(UTC +01:00): Brussels, Copenhagen, Madrid, Paris',
  '(UTC +01:00): Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  '(UTC +02:00): Harare, Pretoria',
  '(UTC +02:00): Damascus',
  '(UTC +02:00): Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  '(UTC +02:00): Windhoek',
  '(UTC +02:00): Minsk',
  '(UTC +02:00): Athens, Bucharest, Istanbul',
  '(UTC +02:00): Amman',
  '(UTC +02:00): Beirut',
  '(UTC +02:00): Jerusalem',
  '(UTC +02:00): Cairo',
  '(UTC +03:00): Kuwait, Riyadh',
  '(UTC +03:00): Moscow, St. Petersburg, Volgograd',
  '(UTC +03:00): Baghdad',
  '(UTC +03:00): Nairobi',
  '(UTC +03:30): Tehran',
  '(UTC +04:00): Port Louis',
  '(UTC +04:00): Tbilisi',
  '(UTC +04:00): Baku',
  '(UTC +04:00): Yerevan',
  '(UTC +04:00): Abu Dhabi, Muscat',
  '(UTC +04:30): Kabul',
  '(UTC +05:00): Yekaterinburg',
  '(UTC +05:00): Islamabad,Karachi',
  '(UTC +05:00): Tashkent',
  '(UTC +05:30): Chennai, Kolkata, Mumbai, New Delhi',
  '(UTC +05:30): Sri Jayawardenepura',
  '(UTC +05:45): Kathmandu',
  '(UTC +06:00): Dhaka',
  '(UTC +06:00): Novosibirsk',
  '(UTC +06:00): Astana',
  '(UTC +06:30): Yangon (Rangoon)',
  '(UTC +07:00): Krasnoyarsk',
  '(UTC +07:00): Bangkok, Hanoi, akarta',
  '(UTC +08:00): Ulaanbaatar',
  '(UTC +08:00): Perth',
  '(UTC +08:00): Taipei',
  '(UTC +08:00): Kuala Lumpur, Singapore',
  '(UTC +08:00): Beijing, Chongqing, Hong Kong, Urumqi',
  '(UTC +08:00): Irkutsk',
  '(UTC +08:30): Pyongyang',
  '(UTC +08:30): Eucla',
  '(UTC +09:00): Seoul',
  '(UTC +09:00): Osaka, Sapporo, Tokyo',
  '(UTC +09:00): Yakutsk',
  '(UTC +09:30): Darwin',
  '(UTC +09:30): Adelaide',
  '(UTC +10:00): Hobart',
  '(UTC +10:00): Vladivostok',
  '(UTC +10:00): Guam, Port Moresby',
  '(UTC +10:00): Brisbane',
  '(UTC +10:00): Canberra, Melbourne, Sydney',
  '(UTC +10:30): Lord Howe Island',
  '(UTC +11:00): Magadan, Solomon Is., New Caledonia',
  '(UTC +12:00): Fiji',
  '(UTC +12:00): Petropavlovsk-Kamchatsky',
  '(UTC +12:00): Auckland, Wellington',
  '(UTC +12:00): Coordinated Universal Time+12',
  '(UTC +12:45): Chatham Islands',
  '(UTC +13:00): Phoenix Islands, Tokelau, Tonga',
]

export const utcZonesShort = utcZones.map((el: string) => ({
  value: el,
  short: el.replace(/.*(UTC\s[-+]\d{2}((?!:00):\d{2})?).*/, '($1)'),
}))

export const utcZonesOptions = utcZones.map((el: string) => ({
  label: el,
}))

export const timeFormats = [
  {
    label: '24 hours',
    val: 'hours24',
    text: 'Your current time is 16:17',
  },
  {
    label: '12 hours',
    val: 'hours12',
    text: 'Your current time is 4:17 pm',
  },
]
