import type { ComputedRef } from 'vue'
import { computed, ref } from 'vue'
import type { ModalNames, ModalSize } from '@/components/modals/types'

type ModalPropsAll = {
  modalTitle: string;
  size: ModalSize;
  exactSize: string;
  noFooter: boolean;
  noHeader: boolean;
  hideCancelButton: boolean;
  cancelButtonText: string;
  titleIcon: string;
  titleIconClass: string;
  modalClasses: string;
  [key: string]: any;
}
export type ModalProps = Partial<ModalPropsAll>
type ModalPropsRecord = Partial<Record<ModalNames, ModalProps>>
type OpenModalFunc = (name: ModalNames, props?: ModalProps) => void
type CloseModalFunc = (name: ModalNames) => void
type UseModalVal = {
  getModalsNames: () => ComputedRef<ModalNames[]>,
  getModalsProps: () => ComputedRef<ModalPropsRecord>,
  openModal: OpenModalFunc,
  closeModal: CloseModalFunc,
  closeAllModals: () => void,
}

const modals = ref<ModalNames[]>([])
const modalProps = ref<ModalPropsRecord>({})

export const useModals = (): UseModalVal => {
  const getModalsNames = () => computed(() => modals.value)
  const getModalsProps = () => computed(() => modalProps.value)
  const openModal: OpenModalFunc = (name, props) => {
    modals.value.push(name)
    if (props) {
      modalProps.value[name] = props
    }
  }
  const closeModal: CloseModalFunc = (name: ModalNames) => {
    modals.value = modals.value.filter((modalName) => modalName !== name)
    delete modalProps.value[name]
  }
  const closeAllModals = () => {
    modals.value = []
    modalProps.value = {}
  }

  return {
    getModalsNames,
    getModalsProps,
    openModal,
    closeModal,
    closeAllModals,
  }
}
