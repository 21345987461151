import { rootScrollClassName } from '@/definitions/_general/_data/general'
import { onMounted, ref } from 'vue'

export const useScroll = (): any => {
  const rootScroll = ref()

  onMounted(() => {
    rootScroll.value = document.querySelector(`.${rootScrollClassName}`)
  })

  return {
    rootScroll,
  }
}
