import type { DaySchedule } from '@/definitions/shared/types'

export const rootScrollClassName = 'root-scroll'
export const dropdownClassName = 'tm-dropdown'
export const observedScrollbarQuasarClassName = 'scroll'
export const pageContentClassName = 'page-content'

export const delayOptions = {
  default: 400,
  long: 1000,
}

export const defaultDateFormat = 'D MMM YYYY'
export const shortDateFormat = 'DD/MM/YYYY'
export const defaultTimeFormat = 'h:mm a'

export const weekScheduleDefault: DaySchedule[] = [
  {
    day: 'sunday',
    active: true,
    schedule: [{ startTime: '8:00 AM', endTime: '9:00 AM' }],
  },
  {
    day: 'monday',
    active: false,
    schedule: [{ startTime: '8:00 AM', endTime: '9:00 AM' }],
  },
  {
    day: 'tuesday',
    active: false,
    schedule: [{ startTime: '8:00 AM', endTime: '9:00 AM' }],
  },
  {
    day: 'wednesday',
    active: true,
    schedule: [{ startTime: '8:00 AM', endTime: '9:00 AM' }],
  },
  {
    day: 'thursday',
    active: true,
    schedule: [{ startTime: '8:00 AM', endTime: '9:00 AM' }],
  },
  {
    day: 'friday',
    active: true,
    schedule: [{ startTime: '8:00 AM', endTime: '9:00 AM' }],
  },
  {
    day: 'saturday',
    active: true,
    schedule: [{ startTime: '8:00 AM', endTime: '9:00 AM' }],
  },
]

export const detailsGridSidebarClass = 'details-grid-sidebar'
