export default [
  {
    value: '1',
    label: 'United States',
    country: {
      name: 'United States',
      id: 'us',
      code: '(855)',
    },
  },
  {
    value: '1250',
    label: 'Canada',
    country: {
      name: 'Canada',
      id: 'ca',
      code: '+1 (250)',
    },
  },
  {
    value: '1',
    label: 'United States',
    country: {
      name: 'United States',
      id: 'us',
      code: '+1',
    },
  },
  {
    value: '44',
    label: 'United Kingdom',
    country: {
      name: 'United Kingdom',
      id: 'uk',
      code: '+44',
    },
  },
  {
    value: '47',
    label: 'Norway',
    country: {
      name: 'Norway',
      id: 'no',
      code: '+47',
    },
  },
  {
    value: '33',
    label: 'France',
    country: {
      name: 'France',
      id: 'fr',
      code: '+33',
    },
  },
  {
    value: '372',
    label: 'Estonia',
    country: {
      name: 'Estonia',
      id: 'ee',
      code: '+372',
    },
  },
  {
    value: '61',
    label: 'Australia',
    country: {
      name: 'Australia',
      id: 'au',
      code: '+61',
    },
  },
  {
    value: '49',
    label: 'Germany',
    country: {
      name: 'Germany',
      id: 'de',
      code: '+49',
    },
  },
]
