import { useModals } from '@/compositions/modals'
import { useCalls } from '@/compositions/chats/chats'

type UseContactsOpenModal = () => {
  openModalNewContact: () => void
  openModalEditContact: () => void
  openAddToListModal: (selected: any[]) => void
  openDeleteContactModal: (contactName: string) => void
  openDeleteContactsModal: (selected: any[]) => void
  openResubscribeContactModal: () => void
  openSettingsModal: () => void
  openBlockContactModal: (title: string) => void
  openBlockIPModal: () => void
  openUnsubscribeContactModal: () => void
  openDeleteCustomFieldModal: (title: string) => void
  openMoveContactsModal: (selected: any[]) => void
  initCall: () => void
  openDownloadToEmailModal: () => void
}

const useContactsOpenModal: UseContactsOpenModal = () => {
  const { openModal } = useModals()

  const openModalNewContact = () => {
    openModal('contact')
  }

  const openResubscribeContactModal = () => {
    openModal('contact', {
      modalTitle: 'Resubscribe contact',
      firstName: 'Leslie',
      lastName: 'Little',
      email: 'leslie@company.com',
      btnText: 'Save',
      company: 'ExxonMobil',
      createAnother: false,
    })
  }

  const openModalEditContact = () => {
    openModal('contact', {
      modalTitle: 'Edit contact',
      firstName: 'Ralph',
      lastName: 'Edwards',
      btnText: 'Save changes',
      email: 'ralph.edwards@gmail.com',
      company: 'Rosenbaum Inc',
      createAnother: false,
    })
  }

  const openSettingsModal = () => {
    openModal('contactsSettings')
  }

  const openDeleteContactModal = (contactName: string) => {
    openModal('confirmation', {
      title: 'Delete contact',
      text: [
        'You are about to delete contact ',
        { text: contactName, style: 'semi-bold' },
        '. The contact has ',
        { text: '12 non-closed tickets.', style: 'semi-bold' },
        ' After deleting the contact, their status will change to CLOSED' +
        '\n\nAre you sure, you want to delete the contact?',
      ],
      btnText: 'Delete',
      btnColor: 'red',
    })
  }

  const openDeleteContactsModal = (selected: any[]) => {
    if (selected.length > 1) {
      openModal('confirmation', {
        title: 'Delete contact',
        text: [
          { text: selected.length + ' contacts', style: 'semi-bold' },
          ' will be deleted. The action cannot be undone. Are you sure you want to delete them?',
        ],
        btnText: 'Delete ' + selected.length + ' contacts',
        btnColor: 'red',
      })
    } else {
      openDeleteContactModal(selected[0].person.fullName)
    }
  }

  const openAddToListModal = (selected: any[]) => {
    openModal('confirmation', {
      title: 'Add contacts to list',
      text: [
        'The selected',
        {
          text: ` ${selected.length} contact${selected.length > 1 ? 's' : ''} `,
          style: 'semi-bold',
        },
        'will be added to the following list:\n',
        {
          text: 'Vip customers. ',
          style: 'semi-bold',
        },
        'Are you sure you would like to continue?',
      ],
      btnText: 'Add to list',
      btnColor: 'primary',
    })
  }

  const openBlockContactModal = (contactName: string) => {
    openModal('confirmation', {
      title: 'Block contact',
      text: [
        'After blocking contact ',
        { text: contactName, style: 'semi-bold' },
        ', you will no longer receive any communication from this contact. Blocked contacts can be viewed in the contacts section.',
      ],
      btnText: 'Block',
      btnColor: 'red',
    })
  }

  const openBlockIPModal = () => {
    openModal('confirmation', {
      title: 'Block IP for 24 hours',
      text: [
        'After blocking the IP address ',
        { text: '132.534.534.34', style: 'semi-bold' },
        ', this visitor will no longer be able make new chat requests. The chat widget will be hidden from this visitor for 24 hours. Are you sure you would like to block this IP?',
      ],
      btnText: 'Block IP',
      btnColor: 'red',
    })
  }

  const openUnsubscribeContactModal = () => {
    openModal('singleCheckbox', {
      modalTitle: 'Unsubscribe contact',
      text: [
        { text: 'Ralph Edwards (603) 555-0123', style: 'semi-bold' },
        ' will be permanently removed from your lists. All future messages to this number will be rejected by Textmagic. This applies to all your sub-accounts too.',
      ],
      btnText: 'Unsubscribe',
      btnColor: 'red',
      label: 'Also block incoming messages from this number.',
    })
  }

  const openDeleteCustomFieldModal = (title: string) => {
    openModal('confirmation', {
      title: 'Delete custom field',
      text: [
        'Are you sure you would like to delete ',
        { text: title, style: 'semi-bold' },
        ' custom contact data field? This action cannot be undone.',
        { text: '\n\nAll data contained in this field will be permanently deleted.', style: 'semi-bold' },
      ],
      btnText: 'Delete',
      btnColor: 'red',
    })
  }
  const openMoveContactsModal = (selected: any[]) => {
    if (selected.length) {
      openModal('contactsMove', {
        selected: selected,
      })
    } else {
      openModal('contactsMove')
    }
  }

  const {
    getActiveCall: activeCall,
    setActiveCall,
    showCall,
  } = useCalls()
  const initCall = () => {
    showCall.value = true
    setActiveCall({
      chatSender: {
        person: undefined,
        phone: '(688) 615-4302',
        email: 'ollie.batz@hotmail.com',
      },
      status: 'waiting',
      type: 'outbound',
      duration: '1:20',
      muted: false,
    })

    setTimeout(() => {
      if (activeCall.value) {
        setActiveCall({
          ...activeCall.value,
          status: 'inProgress',
        })
      }
    }, 2000)
  }

  const openDownloadToEmailModal = () => {
    openModal('singleInput', {
      modalTitle: 'Send the download link to an email',
      description: 'Export of data can take some time. Please enter email address, and we will send a download link as soon as possible.',
      label: 'Email address',
      placeholder: 'Enter email address',
      btnText: 'Send',
    })
  }

  return {
    openModalNewContact,
    openModalEditContact,
    openAddToListModal,
    openDeleteContactModal,
    openDeleteContactsModal,
    openSettingsModal,
    openBlockContactModal,
    openUnsubscribeContactModal,
    openResubscribeContactModal,
    openDeleteCustomFieldModal,
    openMoveContactsModal,
    initCall,
    openBlockIPModal,
    openDownloadToEmailModal,
  }
}

export default useContactsOpenModal
