import { useModals } from '@/compositions/modals'

type UseContactsOrganizationsOpenModal = () => {
  openOrganizationsModal: () => void
  openEditOrganizationsModal: (organization: any) => void
  openDeleteOrganizationModal: (organizationName: string) => void
  openDeleteOrganizationsModal: (selected: any[]) => void
  openOrganizationsNoteModal: () => void
}

const useContactsSegmentsOpenModal: UseContactsOrganizationsOpenModal = () => {
  const { openModal } = useModals()

  const openOrganizationsModal = () => {
    openModal('contactsOrganizations')
  }
  const openEditOrganizationsModal = (row: any) => {
    openModal('contactsOrganizations', {
      btnText: 'Save changes',
      modalTitle: 'Edit organization',
      row: row,
      rowAvatar: require('@/mock/images/organizations/Logo-Org-05.png'),
      createAnother: false,
    })
  }

  const openDeleteOrganizationModal = (organizationName: string) => {
    openModal('contactsGroupDelete', {
      title: 'Delete organization',
      text: [
        'By confirming this action, the ',
        { text: organizationName, style: 'semi-bold' },
        ' organization with be deleted. This action cannot be undone. \n\nPlease select what should happen to the contacts in this organization after it is deleted.',
      ],
      btnText: 'Delete',
      btnColor: 'red',
    })
  }

  const openDeleteOrganizationsModal = (selected: any[]) => {
    if (selected.length > 1) {
      openModal('contactsGroupDelete', {
        title: 'Delete organizations',
        text: [
          { text: selected.length + ' organizations', style: 'semi-bold' },
          ' will be deleted. The action cannot be undone. \n\nPlease select what should happen to the contacts in the selected organizations after they are deleted.',
        ],
        btnText: 'Delete ' + selected.length + ' organizations',
        btnColor: 'red',
      })
    } else {
      openDeleteOrganizationModal(selected[0].organization.organizationName)
    }
  }

  const openOrganizationsNoteModal = () => {
    openModal('note', {
      modalTitle: 'Add organization note',
    })
  }

  return {
    openOrganizationsModal,
    openEditOrganizationsModal,
    openDeleteOrganizationModal,
    openDeleteOrganizationsModal,
    openOrganizationsNoteModal,
  }
}

export default useContactsSegmentsOpenModal
