import { ref, onUnmounted, nextTick } from 'vue'
import { getAnchorRect, getTargetRect, getScrollbarWidth } from '@/services/utils'
import { observedScrollbarQuasarClassName } from '@/definitions/_general/_data/general'

type AngleAttrs = {
  anchor: string;
  self: string;
  offset: [number, number];
  class: string;
}
type RenderTooltip = {
  getAngleAttrs: any;
  show: any;
  hide: any;
}

export type TooltipPosition = 'top' | 'top-left' | 'top-right' |
  'bottom' | 'bottom-left' | 'bottom-right' |
  'left' | 'left-top' | 'left-bottom' |
  'right' | 'right-top' | 'right-bottom'

const getPos = (value: string): { vertical: string; horizontal: string } => {
  const [vertical, horizontal] = value.split(' ')
  return {
    vertical,
    horizontal,
  }
}

export const renderTooltip = (): RenderTooltip => {
  const closestScrolls = ref([] as HTMLElement[])
  let internalAnchorEl: HTMLElement
  let internalMenuEl: HTMLElement
  let internalQMenu: any
  let internalAttrs = {} as AngleAttrs
  let internalRootClass = ''
  let xReversed = false
  let yReversed = false

  const getAngleAttrs = (
    rootClass: string,
    position: TooltipPosition,
    angle: boolean
  ): AngleAttrs => {
    internalRootClass = rootClass
    const attrsSet: {[key: string]: AngleAttrs} = {
      left: {
        anchor: 'center left',
        self: 'center right',
        offset: [0, 0],
        class: `${internalRootClass}--left-center${angle ? '-angle' : ''}`,
      },
      'left-top': {
        anchor: 'bottom left',
        self: 'bottom right',
        offset: [0, 0],
        class: `${internalRootClass}--left-top${angle ? '-angle' : ''}`,
      },
      'left-bottom': {
        anchor: 'top left',
        self: 'top right',
        offset: [0, 0],
        class: `${internalRootClass}--left-bottom${angle ? '-angle' : ''}`,
      },
      right: {
        anchor: 'center right',
        self: 'center left',
        offset: [0, 0],
        class: `${internalRootClass}--right-center${angle ? '-angle' : ''}`,
      },
      'right-top': {
        anchor: 'bottom right',
        self: 'bottom left',
        offset: [0, 0],
        class: `${internalRootClass}--right-top${angle ? '-angle' : ''}`,
      },
      'right-bottom': {
        anchor: 'top right',
        self: 'top left',
        offset: [0, 0],
        class: `${internalRootClass}--right-bottom${angle ? '-angle' : ''}`,
      },
      top: {
        anchor: 'top middle',
        self: 'bottom middle',
        offset: [0, 0],
        class: `${internalRootClass}--top-center${angle ? '-angle' : ''}`,
      },
      'top-left': {
        anchor: 'top right',
        self: 'bottom right',
        offset: angle ? [30, 0] : [0, 0],
        class: `${internalRootClass}--top-left${angle ? '-angle' : ''}`,
      },
      'top-right': {
        anchor: 'top left',
        self: 'bottom left',
        offset: angle ? [30, 0] : [0, 0],
        class: `${internalRootClass}--top-right${angle ? '-angle' : ''}`,
      },
      bottom: {
        anchor: 'bottom middle',
        self: 'top middle',
        offset: [0, 0],
        class: `${internalRootClass}--bottom-center${angle ? '-angle' : ''}`,
      },
      'bottom-left': {
        anchor: 'bottom right',
        self: 'top right',
        offset: angle ? [30, 0] : [0, 0],
        class: `${internalRootClass}--bottom-left${angle ? '-angle' : ''}`,
      },
      'bottom-right': {
        anchor: 'bottom left',
        self: 'top left',
        offset: angle ? [30, 0] : [0, 0],
        class: `${internalRootClass}--bottom-right${angle ? '-angle' : ''}`,
      },
    }

    return { ...attrsSet[position] }
  }

  const definePosition = async (event?: any) => {
    if (!internalAnchorEl || !internalMenuEl) {
      return
    }

    const { anchor: anchorStr, self: selfStr, offset } = internalAttrs
    const anchorPosition = getPos(anchorStr)
    const targetPosition = getPos(selfStr)

    const anchorRect = getAnchorRect(internalAnchorEl, offset)
    const targetRect = getTargetRect(internalMenuEl)
    const halfAngleWidth = 10 / 2
    const xAngleOffset = anchorRect.right - anchorRect.middle - halfAngleWidth
    const yAngleOffset = Math.abs(anchorRect.top - anchorRect.center) - halfAngleWidth
    internalMenuEl.style.setProperty('--x-angle-offset', `${xAngleOffset}px`)
    internalMenuEl.style.setProperty('--y-angle-offset', `${yAngleOffset}px`)

    const getBoundaries = () => ({
      top: anchorRect[anchorPosition.vertical] - targetRect[targetPosition.vertical],
      left: anchorRect[anchorPosition.horizontal] - targetRect[targetPosition.horizontal],
      maxWidth: internalMenuEl.offsetWidth,
      maxHeight: internalMenuEl.offsetHeight,
    })
    const boundaries = getBoundaries()

    const scrollbarWidth = getScrollbarWidth()
    const innerHeight = window.innerHeight - scrollbarWidth
    const { clientWidth: innerWidth } = document.body
    const currentHeight = targetRect.bottom
    const currentWidth = targetRect.right

    const activeScroll = event?.srcElement || closestScrolls.value[0]
    const {
      top: activeScrollOffsetTop,
      left: activeScrollOffsetLeft,
      bottom: activeScrollOffsetBottom,
    } = activeScroll?.getBoundingClientRect() || {}

    // anchorEl visibility check
    if (
      anchorRect.top + anchorRect.height < activeScrollOffsetTop ||
      anchorRect.top + offset[1] > innerHeight ||
      anchorRect.top + offset[1] > activeScrollOffsetBottom ||
      anchorRect.left + anchorRect.width < activeScrollOffsetLeft ||
      anchorRect.left + offset[0] > innerWidth
    ) {
      const lockYScroll = activeScroll.scrollTop
      const lockXScroll = activeScroll.scrollLeft
      internalQMenu?.value.hide()
      await nextTick()
      activeScroll.scrollTop = lockYScroll
      activeScroll.scrollLeft = lockXScroll
      return
    }

    const setYReversedClass = () => {
      const { vertical: anchorV } = anchorPosition
      const { vertical: targetV } = targetPosition
      // cancels repulsion from opposite sides for props: left-top | left-bottom | right-top | right-bottom
      if (
        (boundaries.top < 0 &&
          (anchorV !== targetV || anchorV !== 'top')) ||
        (boundaries.top + currentHeight > innerHeight &&
          (anchorV !== targetV || anchorV !== 'bottom'))
      ) {
        internalMenuEl.classList.add(`${internalRootClass}--y-reversed`)
      }
    }

    yReversed = boundaries.top < 0 || boundaries.top + currentHeight > innerHeight
    if (yReversed) {
      /*
        yReversed if statement was taken from quasar/src/utils/private/position-engine.js
        for correct positioning when two and more scrollbar nesting
        and for a correct understanding of the position on Y axis
       */
      setYReversedClass()
      if (targetPosition.vertical === 'center') {
        /*
          does not allow to go beyond the borders of the yScreen (min: 0, max: innerHeight)
          props: left | right
         */
        boundaries.top = anchorRect[anchorPosition.vertical] > innerHeight / 2
          ? Math.max(0, innerHeight - currentHeight)
          : 0
        boundaries.maxHeight = Math.min(currentHeight, innerHeight)
      } else if (anchorRect[anchorPosition.vertical] > innerHeight / 2) {
        /*
          does not allow to go beyond the bottom border (max: anchorY - currentHeight)
          anchorY like innerHeight, currentHeight like menuEl.offsetHeight
          props everything except: left | right
         */
        const anchorY = Math.min(
          innerHeight,
          anchorPosition.vertical === 'center'
            ? anchorRect.center
            : (anchorPosition.vertical === targetPosition.vertical ? anchorRect.bottom : anchorRect.top)
        )
        boundaries.maxHeight = Math.min(currentHeight, anchorY)
        boundaries.top = Math.max(0, anchorY - currentHeight)
      } else {
        /*
          does not allow to go beyond the top border (min: 0)
          props everything except: left | right
         */
        boundaries.top = Math.max(0, anchorPosition.vertical === 'center'
          ? anchorRect.center
          : (anchorPosition.vertical === targetPosition.vertical ? anchorRect.top : anchorRect.bottom)
        )
        boundaries.maxHeight = Math.min(currentHeight, innerHeight - boundaries.top)
      }
    } else {
      internalMenuEl.classList.remove(`${internalRootClass}--y-reversed`)
    }

    const setXReversedClass = () => {
      const { horizontal: anchorH } = anchorPosition
      const { horizontal: targetH } = targetPosition
      // cancels repulsion from opposite sides for props: top-left | top-right | bottom-left | bottom-right
      if (
        (boundaries.left < 0 &&
          (anchorH !== targetH || anchorH !== 'left')) ||
        (boundaries.left + currentWidth > innerWidth &&
          (anchorH !== targetH || anchorH !== 'right'))
      ) {
        internalMenuEl.classList.add(`${internalRootClass}--x-reversed`)
      }
    }

    xReversed = boundaries.left < 0 || boundaries.left + currentWidth > innerWidth
    if (xReversed) {
      /*
        xReversed if statement was taken from quasar/src/utils/private/position-engine.js
        for correct positioning when two and more scrollbar nesting
        and for a correct understanding of the position on X axis
       */
      setXReversedClass()
      boundaries.maxWidth = Math.min(currentWidth, innerWidth)
      if (targetPosition.horizontal === 'middle') {
        /*
          does not allow to go beyond the borders of the xScreen (min: 0, max: innerWidth - currentWidth)
          props: top | bottom
         */
        boundaries.left = anchorRect[anchorPosition.horizontal] > innerWidth / 2
          ? Math.max(0, innerWidth - currentWidth)
          : 0
      } else if (anchorRect[anchorPosition.horizontal] > innerWidth / 2) {
        /*
          does not allow to go beyond the right border (max: anchorX - boundaries.maxWidth)
          anchorY like innerWidth, boundaries.maxWidth like menuEl.offsetWidth
          props everything except: top | bottom
         */
        const anchorX = Math.min(
          innerWidth,
          anchorPosition.horizontal === 'middle'
            ? anchorRect.middle
            : (anchorPosition.horizontal === targetPosition.horizontal ? anchorRect.right : anchorRect.left)
        )
        boundaries.maxWidth = Math.min(currentWidth, anchorX)
        boundaries.left = Math.max(0, anchorX - boundaries.maxWidth)
      } else {
        /*
          does not allow to go beyond the left border (min: 0)
          props everything except: top | bottom
         */
        boundaries.left = Math.max(0, anchorPosition.horizontal === 'middle'
          ? anchorRect.middle
          : (anchorPosition.horizontal === targetPosition.horizontal ? anchorRect.left : anchorRect.right)
        )
        boundaries.maxWidth = Math.min(currentWidth, innerWidth - boundaries.left)
      }
    } else {
      internalMenuEl.classList.remove(`${internalRootClass}--x-reversed`)
    }

    internalMenuEl.style.top = `${boundaries.top}px`
    internalMenuEl.style.left = `${boundaries.left}px`
  }

  const show = async (anchorEl: HTMLElement, menuEl: HTMLElement, attrs: AngleAttrs, qMenu?: any) => {
    internalAttrs = attrs
    internalAnchorEl = anchorEl
    internalMenuEl = menuEl
    internalQMenu = qMenu
    closestScrolls.value = getScrollbarWraps(internalAnchorEl)

    definePosition()
    setListeners()
  }

  const getScrollbarWraps = (el: HTMLElement, acc: HTMLElement[] = []) => {
    if (el?.parentElement && el.parentElement !== document.body) {
      if (el.parentElement.classList.contains(observedScrollbarQuasarClassName)) {
        acc.push(el.parentElement)
      }
      getScrollbarWraps(el.parentElement, acc)
    }

    return acc
  }

  onUnmounted(() => {
    closestScrolls.value.forEach((scroll) => {
      scroll.removeEventListener('scroll', definePosition)
    })
  })

  const setListeners = () => {
    closestScrolls.value.forEach((scroll) => {
      scroll.addEventListener('scroll', definePosition)
    })
  }

  const unsetListeners = () => {
    closestScrolls.value.forEach((scroll) => {
      scroll.removeEventListener('scroll', definePosition)
    })
  }

  return {
    getAngleAttrs,
    show,
    hide: unsetListeners,
  }
}
